<template>
  <div id="hotlink">
    <PageHeader
      :pageHeader="pageHeader"
      :kvTitle1Color="kvTitle1Color"
      :kvTitle2Color="kvTitle2Color"
    />
    <router-view/>
  </div>
</template>

<script>
import '@/assets/scss/hotlink.scss';

import PageHeader from '@/components/PageHeader.vue';
import { HotlinkKv } from '@/lib/const';

export default {
  title: '快速連結',
  name: 'HotlinkHome',
  data() {
    return {
      ...HotlinkKv,
      kvTitle1Color: 'txt-white',
      kvTitle2Color: 'txt-white',
    };
  },
  components: {
    PageHeader,
  },
};
</script>
